import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import theme from '../constants/theme'
import Para from '../components/Para'
import H2 from '../components/H2'
import HeroTitle from '../components/HeroTitle'
import BG from '../components/BG'
import Section from '../components/Section'
import EventCard from '../components/EventCard'
import Img from 'gatsby-image'
import { ThemeProvider } from 'emotion-theming'
import Link from '../components/Link'
import { FormattedMessage } from 'react-intl'

const FeaturedBlogLink = styled(Link)`
  position: relative;
  display: block;
  height: 100%;
  img {
    width: 100%;
    position: relative;
  }
`

const FeaturedBlogLinkTitle = styled(Para)`
  position: absolute;
  /* font-size: ${theme.text.h2}; */
  color: ${theme.colors.blackAlt};
  left:0;
  bottom: 0;
  background-color: ${theme.colors.white};
  margin-bottom: 0;
  max-width: 90%;
  padding: 24px 18px;
  border-top-right-radius: 12px;
`

// const dates = [new Date(2018, 12, 1), new Date(2018, 12, 5)]
export default class Users extends React.Component {
  state = {
    date: new Date(),
  }

  onChange = date => this.setState({ date })

  render() {
    const {
      data,
      // pageContext: { locale },
    } = this.props
    return (
      <div>
        <ThemeProvider theme={theme.palette.blue}>
          <FormattedMessage id="users.blurb">
            {blurb => (
              <FormattedMessage id="users.title">
                {text => (
                  <HeroTitle
                    title={text}
                    description={[blurb]}
                    includeScrollChevron
                  />
                )}
              </FormattedMessage>
            )}
          </FormattedMessage>
        </ThemeProvider>
      </div>
    )
  }
}

export const query = graphql`
  query {
    aeaLogo: file(relativePath: { eq: "community/events/aea.jpg" }) {
      ...AwardLogo
    }
    techLogo: file(relativePath: { eq: "community/events/tech.png" }) {
      ...AwardLogo
    }
    amatycLogo: file(relativePath: { eq: "community/events/amatyc.png" }) {
      ...AwardLogo
    }

    featuredBlogImg: file(relativePath: { eq: "community/featured_blog.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 730, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const awardLogoFrag = graphql`
  fragment AwardLogo on File {
    childImageSharp {
      fixed(width: 150, quality: 90) {
        ...GatsbyImageSharpFixed_noBase64
      }
    }
  }
`
